<template>
  <v-app v-if="$auth.ready()">
    <app-bar :drawer="appDrawer" v-if="appWrapper"> </app-bar>
    <app-drawer v-if="appDrawer && appWrapper" :drawer="appDrawer"></app-drawer>
    <admin-drawer :drawer="drawer" v-if="!appWrapper"></admin-drawer>

    <admin-bar app :drawer="drawer" v-if="!appWrapper"> </admin-bar>
    <v-main>
      <v-container fluid>
        <transition mode="out-in" name="router-fade">
          <router-view />
        </transition>
      </v-container>
    </v-main>
    <app-bar-mobile v-if="appWrapper"> </app-bar-mobile>
  </v-app>
</template>
<script>
export default {
  components: {
    AdminDrawer: () => import("@/components/core/navigation/AdminDrawer"),
    AdminBar: () => import("@/components/core/navigation/AdminBar"),
    AppBar: () => import("@/components/core/navigation/AppBar"),
    AppDrawer: () => import("@/components/core/navigation/AppDrawer"),
    AppBarMobile: () => import("@/components/core/navigation/AppBarMobile")
  },
  data() {
    return {
      drawer: true,
      appDrawer: false
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    appWrapper() {
      return !this.isAdmin();
    }
  },
  created() {
    let _this = this;
    Event.$on("admin-drawer-update", (value) => {
      _this.drawer = value;
    });
    Event.$on("app-drawer-update", (value) => {
      _this.appDrawer = value;
    });
  }
};
</script>

<style>
.button-primary-gradient {
  background: linear-gradient(
    90deg,
    rgba(64, 64, 64, 1) 0%,
    rgba(64, 64, 64, 1) 39%,
    rgba(229, 229, 229, 1) 100%
  );
}
</style>
